import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import "../assets/css/style.css"
import "../assets/css/responsive.css"
import "../assets/css/vendor.css"

import logoProposte from "../images/logo-proposte.svg"
import Contact from "../components/contact"

let imagealt = "Sogno Casa Liguria"

export default function TutteLeLocalita() {
  const [localita, setLocalita] = useState("/tutte-le-localita/")

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpAppartamento(sort: { fields: date, order: DESC }) {
            nodes {
              title
              slug
              appartamento {
                citta {
                  ... on WpLocalita {
                    id
                    title
                    slug
                  }
                }
                extra
                prezzo
                immagine {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          allWpProprieta(sort: { fields: title, order: ASC }) {
            nodes {
              title
              slug
              proprieta {
                tipologia
                etichetta
                venduta
                citta {
                  ... on WpLocalita {
                    id
                    title
                    slug
                  }
                }
                immagine {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          allWpLocalita {
            nodes {
              title
              slug
              id
              localita {
                immagine {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
          wpLocalita(id: { eq: "cG9zdDoxNzcw" }) {
            localita {
              immagine {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          allWpPage(filter: { id: { eq: "cG9zdDoxMTM=" } }) {
            nodes {
              banner {
                immagine {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              cerca {
                cerca {
                  cerca {
                    ... on WpLocalita {
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Tutte le località" />
          <BackgroundImage
            Tag="section"
            className="breadcrumb-area jarallax"
            fluid={{
              ...data.allWpLocalita.nodes[0].localita.immagine.localFile
                .childImageSharp.fluid,
              sizes: "(max-width: 512px) 80vw",
            }}
            backgroundColor={`#C4BAAD`}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner">
                    <h1 className="page-title">Tutte le località</h1>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <div className="main-search-area">
            <div className="container">
              <div className="banner-search-wrap">
                <ul className="nav nav-tabs rld-banner-tab">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#localita"
                      style={{ pointerEvents: "none" }}
                    >
                      Trova
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="localita">
                    <div className="rld-main-search">
                      <div className="row">
                        <div className="col-xl-10 col-lg-8 col-md-8">
                          <div className="rld-single-select">
                            <label
                              className="select-parent"
                              htmlFor="label-select"
                            >
                              <span>Seleziona località:</span>
                              <select
                                id="label-select"
                                className="select single-select"
                                onBlur={e => setLocalita(e.currentTarget.value)}
                              >
                                <option value="/tutte-le-localita/" key={-1}>
                                  Tutte le località
                                </option>
                                {data.allWpPage.nodes[0].cerca.cerca.cerca.map(
                                  (node, i) => (
                                    <option value={"../" + node.slug} key={i}>
                                      {node.title}
                                    </option>
                                  )
                                )}
                              </select>
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4">
                          <Link className="btn btn-yellow" to={localita}>
                            CERCA
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="property-area pd-top-100">
            <div className="container">
              <div className="property-filter-area row custom-gutter">
                {data.allWpProprieta.nodes.map((node, i) => {
                  return (
                    <div key={i} className="col-xl-6 col-lg-6">
                      <div className="single-leading-feature">
                        <Link to={"../" + node.slug}>
                          <div className="slf-overlay" />
                          <div className="thumb">

                          {/* test */}
                          {(function () {
                            if (node.proprieta.etichetta ) {
                              return (
                                <span className="etichetta etichetta-list etichetta-list-big"
                                  dangerouslySetInnerHTML={{
                                    __html: `${node.proprieta.etichetta}`,
                                  }}
                                ></span>
                              )
                            } else {
                              return ""
                            }
                          })()}

                          {(function () {
                            if (node.proprieta.venduta ) {
                              return (
                                <span className="etichetta etichetta-list etichetta-list-big venduta"
                                  dangerouslySetInnerHTML={{
                                    __html: `${node.proprieta.venduta}`,
                                  }}
                                ></span>
                              )
                            } else {
                              return ""
                            }
                          })()}
                          {/* /test */}

                            <Img
                              fluid={{
                                ...node.proprieta.immagine.localFile
                                  .childImageSharp.fluid,
                                sizes: "(max-width: 512px) 80vw",
                              }}
                              alt={imagealt}
                            />
                          </div>
                          <div className="details">
                            <span>{node.proprieta.citta[0].title}</span>
                            <h4 className="title readeal-top">{node.title}</h4>
                            <span
                              className="tipologia"
                              dangerouslySetInnerHTML={{
                                __html: `${node.proprieta.tipologia}`,
                              }}
                            ></span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                })}
                {data.allWpAppartamento.nodes.map((node, i) => (
                  <div
                    key={i}
                    className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 single-feature-appartamento"
                  >
                    <div className="single-feature">
                      <Link to={"../" + node.slug}>
                        <div className="thumb">
                          <Img
                            fluid={{
                              ...node.appartamento.immagine.localFile
                                .childImageSharp.fluid,
                              sizes: "(max-width: 512px) 80vw",
                            }}
                            alt={imagealt}
                          />
                        </div>
                      </Link>
                      <div className="details">
                        <a href="/" className="feature-logo">
                          <img src={logoProposte} alt={imagealt} />
                        </a>
                        <p className="author">
                          {node.appartamento.citta[0].title}
                        </p>
                        <h6 className="title readeal-top">
                          <Link to={"../" + node.slug}>{node.title}</Link>
                        </h6>
                        da Euro{" "}
                        <h6 className="price"> {node.appartamento.prezzo}</h6>
                        <del>{node.olderprice}</del>
                        <ul className="info-list">
                          {(function () {
                            if (node.appartamento.extra === true) {
                              return (
                                <li>
                                  <i className="fa fa-car" /> Possibilità posto
                                  auto
                                </li>
                              )
                            } else {
                              return ""
                            }
                          })()}
                        </ul>
                        <ul className="contact-list">
                          <li>
                            <a
                              className="phone"
                              href="tel:+393391745031"
                              aria-label="icon-phone"
                            >
                              <i className="fa fa-phone" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="message"
                              href="mailto:info@sognocasaliguria.it"
                              aria-label="icon-mail"
                            >
                              <i className="fa fa-envelope" />
                            </a>
                          </li>
                          <li className="readeal-top dettagli-btn">
                            <Link
                              className="btn btn-yellow"
                              to={"../" + node.slug}
                            >
                              Dettagli
                            </Link>
                          </li>
                          <li className="arrow-mobile">
                            <Link
                              className="arrow"
                              aria-label="icon-arrow"
                              to={"../" + node.slug}
                            >
                              <i className="fa fa-share" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Contact bgGrayOpacity="bg-gray-opacity" />
        </Layout>
      )}
    />
  )
}
